<template>
  <div class="page flex-col justify-between">
    <div class="block_1 flex-col">
      <div class="group_1 flex-row">
        <div class="image-wrapper_1 flex-col">
          <top-logo class="image_1"></top-logo>
        </div>
        <div class="box_1 flex-col justify-between">
          <top-nav></top-nav>
        </div>
      </div>
      <div class="text-wrapper_1">
        <span class="paragraph_1">
          その先の未来へ挑む。
          <br />
          人類の合理的な明るい未来ヘ。
          <br />
        </span>
        <span class="paragraph_2"><br /></span>
      </div>
      <div class="group_2 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/69a9534a7135574796ddffd582184cc0.png"
        />
        <div class="text-wrapper_2">Still&nbsp;More</div>
      </div>
    </div>
    <div class="block_5 flex-col">
      <div class="box_2 flex-col">
        <div class="section_1 flex-row justify-between">
          <div class="section_2 flex-col">
            <div class="text-wrapper_3">
              <span class="paragraph_4">
                Salesforce
                <br />
              </span>
              <span class="text_2"></span>
            </div>
            <span class="paragraph_5">
              大手通販会社CRMシステム
              <br />
              <br />
              <br />
            </span>
            <span class="text_3">
              私たちは、大手通販会社の顧客関係管理（CRM）システムの刷新に際して、Salesforce&nbsp;(SFDC)&nbsp;の導入支援を行いました。このプロジェクトは、顧客データの一元管理、営業プロセスの効率化、顧客満足度の向上を目指して実施されました。
            </span>
            <div class="block_6 flex-row">
              <span class="paragraph_6">
                01
                <br />
              </span>
              <div class="box_3 flex-col"></div>
              <span class="paragraph_7">
                04
                <br />
              </span>
            </div>
          </div>
          <div class="section_3 flex-col">
            <div class="group_3 flex-col align-center">
              <div class="group_4 flex-col">
                <div class="group_5 flex-col">
                  <div class="block_7 flex-col justify-end">
                    <div class="text-wrapper_4">
                      <span class="paragraph_8">
                        Salesforce
                        <br />
                      </span>
                      <span class="text_4"></span>
                    </div>
                    <span class="paragraph_9">
                      大手通販会社CRMシステム
                      <br />
                      <br />
                    </span>
                    <div class="image-wrapper_2 flex-col">
                      <img
                        class="label_1"
                        referrerpolicy="no-referrer"
                        src="./assets/img/6d6850947932c77e7a4337cfa0d23859.png"
                      />
                    </div>
                  </div>
                </div>
                <div class="group_6 flex-row">
                  <div class="text-wrapper_5">
                    <span class="paragraph_10">
                      SAP
                      <br />
                    </span>
                    <span class="text_5"></span>
                  </div>
                  <div class="text-wrapper_6">
                    <span class="text_6">人材育成</span>
                    <span class="paragraph_11">
                      <br />
                      <br />
                    </span>
                    <span class="paragraph_12"><br /></span>
                  </div>
                  <div class="text-wrapper_7">
                    <span class="paragraph_13">
                      Web&nbsp;System
                      <br />
                      <br />
                    </span>
                    <span class="paragraph_14"><br /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/d8a8284b87c3929df2e21cabc1558974.png"
        />
        <div class="section_4 flex-col"></div>
      </div>
    </div>
    <div class="block_8 flex-col">
      <div class="text-wrapper_8">
        <span class="paragraph_15">
          Copyright&nbsp;©&nbsp;O K C
          株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_7"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />