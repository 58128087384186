<template>
  <div class="page flex-col justify-end">
    <div class="image-wrapper_1 flex-row">
      <top-logom class="image_2" />
      <top-navm class="image_3" />
    </div>
    <div class="group_1 flex-col">
      <div class="text-wrapper_1">
        <span class="paragraph_1">
          その先の未来へ挑む。
          <br />
          人類の合理的な明るい未来ヘ。
          <br />
        </span>
        <span class="paragraph_2"><br /></span>
      </div>
    </div>
    <div class="group_2 flex-col justify-end">
      <div class="text-wrapper_2">
        <span class="text_1">About</span>
        <span class="paragraph_3">
          &nbsp;OKC
          <br />
        </span>
        <span class="text_2"></span>
      </div>
      <span class="paragraph_4">
        人工知能や最新の技術と共生する世界は、SF映画の中のものだけではありません。
        <br />
        すでに秒進分歩のスピードで目まぐるしい変化を感じると思います。
        <br />
        あらゆる場面で生活を豊かにしてくれるAIとの共生社会の実現が、当社の使命です。
      </span>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/9691da34b476c937d3949b2bba1db542.png"
      />
      <div class="section_2 flex-row">
        <div class="image-text_1 flex-row justify-between">
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/8af9b6ee0e2bf3274c6e6d5841c9beba.png"
          />
          <div class="text-group_1">
            <span class="paragraph_6">
              Still&nbsp;More
              <br />
            </span>
            <span class="text_4"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="group_3 flex-col justify-end">
      <span class="paragraph_7">
        Ovice
        <br />
        <br />
        <br />
      </span>
      <div class="block_1 flex-row justify-between">
        <div class="group_4 flex-col">
          <div class="box_1 flex-row">
            <div class="text-wrapper_4">
              <span class="paragraph_8">
                認定資格
                <br />
              </span>
              <span class="text_5"></span>
            </div>
          </div>
          <div class="box_2 flex-row">
            <div class="text-wrapper_5">
              <span class="paragraph_9">
                Qualification
                <br />
              </span>
              <span class="text_6"></span>
            </div>
          </div>
          <div class="box_3 flex-row justify-between">
            <span class="paragraph_10">
              私たちと協働している
              <br />
              <br />
              <br />
              <br />
            </span>
            <div class="image-wrapper_3 flex-col">
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/6aa400868ca0373f7d0b52b3a560d038.png"
              />
            </div>
          </div>
        </div>
        <div class="group_5 flex-col">
          <div class="group_6 flex-row">
            <div class="text-wrapper_6">
              <span class="paragraph_11">
                アクセス
                <br />
              </span>
              <span class="text_7"></span>
            </div>
          </div>
          <div class="group_7 flex-row">
            <div class="text-wrapper_7">
              <span class="paragraph_12">
                Access
                <br />
              </span>
              <span class="text_8"></span>
            </div>
          </div>
          <div class="group_8 flex-row justify-between">
            <span class="paragraph_13">
              アクセス情報
              <br />
              <br />
              <br />
            </span>
            <div class="image-wrapper_4 flex-col">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/f460beceb6844f772ac5c454dd2f200c.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="block_2 flex-row justify-between">
        <div class="box_4 flex-col">
          <div class="group_9 flex-row">
            <div class="text-wrapper_8">
              <span class="paragraph_14">
                沿革
                <br />
              </span>
              <span class="text_9"></span>
            </div>
          </div>
          <div class="group_10 flex-row">
            <div class="text-wrapper_9">
              <span class="paragraph_15">
                History
                <br />
              </span>
              <span class="text_10"></span>
            </div>
          </div>
          <div class="group_11 flex-row justify-between">
            <span class="paragraph_16"> 創業から現在までの軌跡 </span>
            <div class="image-wrapper_5 flex-col">
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="./assets/img/b59c43f674ff36688f1ebe9911e54d4f.png"
              />
            </div>
          </div>
        </div>
        <div class="box_5 flex-col">
          <div class="box_6 flex-row">
            <div class="text-wrapper_10">
              <span class="paragraph_17">
                ごあいさつ
                <br />
              </span>
              <span class="text_11"></span>
            </div>
          </div>
          <div class="box_7 flex-row">
            <div class="text-wrapper_11">
              <span class="paragraph_18">
                Greeting
                <br />
              </span>
              <span class="text_12"></span>
            </div>
          </div>
          <div class="box_8 flex-row justify-between">
            <span class="paragraph_19">
              ユーザー目線に立ったサービスで
              <br />
              <br />
              <br />
              <br />
            </span>
            <div class="image-wrapper_6 flex-col">
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="./assets/img/6385d2de2b69dd7a9b4aae61fa9fd842.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="block_3 flex-row justify-between">
        <div class="section_3 flex-col">
          <div class="group_12 flex-row">
            <div class="text-wrapper_12">
              <span class="paragraph_20">
                企業理念
                <br />
              </span>
              <span class="text_13"></span>
            </div>
          </div>
          <div class="group_13 flex-row">
            <div class="text-wrapper_13">
              <span class="paragraph_21">
                Philosophy
                <br />
              </span>
              <span class="text_14"></span>
            </div>
          </div>
          <div class="group_14 flex-row justify-between">
            <span class="paragraph_22">
              お客様の満足を最優先に
              <br />
              <br />
              <br />
              <br />
            </span>
            <div class="image-wrapper_7 flex-col">
              <img
                class="label_6"
                referrerpolicy="no-referrer"
                src="./assets/img/4e02ce625cd9ddad05c484d90b8580e3.png"
              />
            </div>
          </div>
        </div>
        <div class="section_4 flex-col">
          <div class="box_9 flex-row">
            <div class="text-wrapper_14">
              <span class="paragraph_23">
                会社概要
                <br />
              </span>
              <span class="text_15"></span>
            </div>
          </div>
          <div class="box_10 flex-row">
            <div class="text-wrapper_15">
              <span class="paragraph_24">
                Outline
                <br />
              </span>
              <span class="text_16"></span>
            </div>
          </div>
          <div class="box_11 flex-row justify-between">
            <span class="paragraph_25">
              私たちの旅はまだ始まったばかりです
              <br />
              <br />
              <br />
              <br />
            </span>
            <div class="image-wrapper_8 flex-col">
              <img
                class="label_7"
                referrerpolicy="no-referrer"
                src="./assets/img/3d7ba78789b172bc62c41de90db89056.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="block_4 flex-col">
        <div class="text-wrapper_16">
          <span class="paragraph_26">
            Copyright&nbsp;©&nbsp;O K C
            株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
            <br />
          </span>
          <span class="text_17"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />