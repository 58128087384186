<template>
  <div class="page flex-col justify-end">
    <div class="image-wrapper_1 flex-row">
      <top-logom class="image_2" />
      <top-navm class="image_3" />
    </div>
    <div class="box_1 flex-col">
      <div class="text-wrapper_1">
        <span class="paragraph_1">
          その先の未来へ挑む。
          <br />
          人類の合理的な明るい未来ヘ。
          <br />
        </span>
        <span class="paragraph_2"><br /></span>
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="text-wrapper_2">
        <span class="text_1">Our</span>
        <span class="paragraph_3">
          &nbsp;Servise
          <br />
        </span>
        <span class="text_2"></span>
      </div>
      <span class="paragraph_4">
        新しい時代の移り変わりにリーチして、イノベーションを起こす。
        <br />
        私たちが行う4部門の事業内容をご紹介いたします。最新の技術で人類を未来へと導く事業です。
        <br />
        秒進分歩の技術革新と、新しい未来の実現をぜひご期待ください。
      </span>
    </div>
    <div class="box_3 flex-row">
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/5634c013a6a5a4ef0da6fa34ff26141c.png"
      />
      <div class="group_1 flex-col">
        <div class="text-wrapper_3">
          <span class="text_3">SE</span>
          <span class="text_4">・</span>
          <span class="paragraph_5"> Consultant </span>
          <span class="paragraph_6">
            <br />
            <br />
          </span>
          <span class="text_6"></span>
        </div>
        <div class="text-wrapper_4">
          <span class="paragraph_7">
            IT人材育成事業
            <br />
          </span>
          <span class="text_7"></span>
        </div>
        <span class="paragraph_8">
          未来を創るIT人材へ
          <br />
          技術と洞察で明日をリード
          <br />
          技術革新の最前線で活躍するIT人材を育成しま...
        </span>
      </div>
    </div>
    <div class="box_4 flex-row">
      <div class="block_1 flex-col">
        <div class="text-wrapper_5">
          <span class="paragraph_9">
            SAP
            <br />
            <br />
          </span>
          <span class="text_8"></span>
        </div>
        <div class="text-wrapper_6">
          <span class="paragraph_10">
            開発・導入支援
            <br />
          </span>
          <span class="text_9"></span>
        </div>
        <span class="paragraph_11">
          ビジネス変革を加速
          <br />
          SAPで創造する新たな価値
          <br />
          SAPを核としたビジネス変革を実現します。最適化されたプロセスと統合され...
        </span>
      </div>
      <div class="image-wrapper_2 flex-col">
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/123ea69d9c285333a6a09be047414928.png"
        />
      </div>
    </div>
    <div class="box_5 flex-row">
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/b3296b92803f951a72da00d58bb2d1e2.png"
      />
      <div class="section_1 flex-col">
        <div class="text-wrapper_7">
          <span class="text_10">Salesforce</span>
          <span class="paragraph_12">
            <br />
          </span>
          <span class="paragraph_13">
            <br />
            <br />
          </span>
          <span class="text_13"></span>
        </div>
        <div class="text-wrapper_8">
          <span class="paragraph_14">
            開発・導入支援
            <br />
          </span>
          <span class="text_14"></span>
        </div>
        <span class="text_15">
          Salesforceは、ビジネスのあらゆる側面にわたってカスタマイズ可能なクラウドベースのCRM（顧客関係管理）ソリューションを提供
        </span>
      </div>
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="./assets/img/5b6298b250ce87e56b1e2c07db047bda.png"
      />
    </div>
    <div class="box_6 flex-col">
      <div class="text-wrapper_9">
        <span class="paragraph_15">
          Copyright&nbsp;©&nbsp;O K C
          株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_16"></span>
      </div>
    </div>
    <div class="box_7 flex-col">
      <div class="section_2 flex-row">
        <div class="box_8 flex-col">
          <div class="text-wrapper_10">
            <span class="paragraph_16">
              Web&nbsp;System
              <br />
              <br />
            </span>
            <span class="text_17"></span>
          </div>
          <div class="text-group_1 flex-col justify-between">
            <span class="paragraph_17">
              Webシステム開発
              <br />
              <br />
            </span>
            <span class="paragraph_18">
              導入支援
              <br />
              <br />
            </span>
          </div>
          <div class="text-wrapper_11">
            <span class="paragraph_19">
              未来を形作るウェブソリューション
              <br />
              あなたのビジョンを現実に
              <br />
            </span>
            <span class="paragraph_20">
              私たちは、最新の技術トレンドを駆使し、ユーザー..
              <br />
              <br />
            </span>
          </div>
        </div>
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="./assets/img/1651084e1f39a63fa96297f1a57664eb.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />