<template>
  <div class="page flex-col justify-end">
    <div class="group_1 flex-col">
      <div class="text-wrapper_1 flex-col">
        <span class="text_1">OKC&nbsp;NEWS</span>
      </div>
      <div class="text-wrapper_2 flex-row justify-between">
        <span class="text_2">2024/04/21</span>
        <span class="text_3">13:23:11</span>
      </div>
      <span class="text_4">5/16(木)〜18(土)に開催される</span>
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/5448b5c8a785ff12a016a08e1ce92d66.png"
      />
      <span class="paragraph_1">
        &nbsp;&nbsp;&nbsp;&nbsp;文書のスキャンデータからオフィス系ファイルまでを横断して全文検索が可能なAIエンタープライズサーチです。インフォディオが独自開発したAI-OCRエンジンを搭載し、スキャンした画像の中の文字を高精度にデータ抽出いたします。併せてスマートOCRも展示しておりますので、スマートOCRのデモをご覧いただくことも可能です！messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;文書のスキャンデータからオフィス系ファイルまでを横断して全文検索が可能なAIエンタープライズサーチです。インフォディオが独自開発したAI-OCRエンジンを搭載し、スキャンした画像の中の文字を高精度にデータ抽出いたします。併せてスマートOCRも展示しておりますので、スマートOCRのデモをご覧いただくことも可能です！messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。文書のスキャンデータからオフィス系ファイルまでを横断して全文検索が可能なAIエンタープライズサーチです。インフォディオが独自開発したAI-OCRエンジンを搭載し、スキャンした画像の中の文字を高精度にデータ抽出いたします。併せてスマートOCRも展示しておりますので、スマートOCRのデモをご覧いただくことも可能です！messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
        <br />
      </span>
      <div class="image-wrapper_1 flex-row">
        <top-logom class="image_3" />
        <top-navm class="image_4" />
      </div>
    </div>
    <div class="group_2 flex-col">
      <div class="text-wrapper_3">
        <span class="paragraph_2">
          Copyright&nbsp;©&nbsp;O K C
          株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_5"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />