<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="box_1 flex-row">
        <div class="image-wrapper_1 flex-col">
          <top-logo class="image_1"></top-logo>
        </div>
        <div class="block_1 flex-col justify-between">
          <top-nav></top-nav>
        </div>
      </div>
      <div class="text-wrapper_1">
        <span class="paragraph_1">
          その先の未来へ挑む。
          <br />
          人類の合理的な明るい未来ヘ。
          <br />
        </span>
        <span class="paragraph_2"><br /></span>
      </div>
      <div class="box_2 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/ebbbc3bad0330330dec634ebbbb8f242.png"
        />
        <div class="text-wrapper_2">Still&nbsp;More</div>
      </div>
    </div>
    <div class="group_2 flex-col">
      <div class="box_3 flex-row">
        <div class="text-wrapper_3">
          <span class="paragraph_4">
            Our&nbsp;Servise
            <br />
          </span>
          <span class="text_2"></span>
        </div>
      </div>
      <div class="text-wrapper_4 flex-row">
        <span class="paragraph_5">
          新しい時代の移り変わりにリーチして、イノベーションを起こす。
          <br />
          私たちが行う4部門の事業内容をご紹介いたします。最新の技術で人類を未来へと導く事業です。
          <br />
          秒進分歩の技術革新と、新しい未来の実現をぜひご期待ください。
        </span>
      </div>
      <div class="box_4 flex-row">
        <div class="text-wrapper_5">
          <span class="paragraph_6">
            Web&nbsp;System
            <br />
            <br />
          </span>
          <span class="text_3"></span>
        </div>
      </div>
      <div class="text-wrapper_6 flex-row">
        <span class="paragraph_7">
          Webシステム開発・導入支援
          <br />
          <br />
          <br />
        </span>
      </div>
      <div class="box_5 flex-row justify-between">
        <div class="text-wrapper_7">
          <span class="paragraph_8">
            未来を形作るウェブソリューション
            <br />
            あなたのビジョンを現実に
            <br />
          </span>
          <span class="paragraph_9">
            私たちは、最新の技術トレンドを駆使し、ユーザーフレンドリーでセキュア、かつスケーラブルなウェブシステムの開発に注力しています。あなたのビジネスビジョンを実現するためのパートナーとして、アイデアの具現化からシステム導入、そしてその後のサポートまで、一貫したサービスを提供します。
            <br />
            <br />
          </span>
        </div>
        <div class="section_1 flex-col">
          <div class="text-wrapper_8">
            <span class="text_4">SE</span>
            <span class="text_5">・</span>
            <span class="paragraph_10"> Consultant </span>
            <span class="paragraph_11">
              <br />
              <br />
            </span>
            <span class="text_7"></span>
          </div>
          <div class="text-wrapper_9">
            <span class="paragraph_12">
              IT人材育成事業
              <br />
            </span>
            <span class="text_8"></span>
          </div>
        </div>
      </div>
      <div class="box_6 flex-row">
        <div class="text-wrapper_10">
          <span class="paragraph_13">
            未来を創るIT人材へ
            <br />
            技術と洞察で明日をリード
            <br />
          </span>
          <span class="text_9">
            技術革新の最前線で活躍するIT人材を育成します。実践的なスキルと深い業界理解を融合させ、変化を先導するプロフェッショナルを育て上げます。あなたのキャリアを加速させ、未来のIT業界を形作る力となるための一歩を、ここから始めましょう。
          </span>
        </div>
      </div>
      <div class="box_7 flex-row">
        <div class="image-wrapper_2 flex-col">
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/5efdcd2bf78ed79cebe47d208879f213.png"
          />
        </div>
      </div>
      <div class="box_8 flex-row justify-between">
        <div class="group_3 flex-col">
          <!-- <div class="text-wrapper_11">
            <span class="paragraph_14">
              SAP
              <br />
              <br />
            </span>
            <span class="text_10"></span>
          </div> -->
          <div class="text-wrapper_5">
            <span class="paragraph_6">
              SAP
              <br />
              <br />
            </span>
            <span class="text_3"></span>
          </div>
          <div class="text-wrapper_12">
            <span class="paragraph_7">
              開発・導入支援
              <br />
            </span>
            <span class="text_11"></span>
          </div>
          <div class="text-wrapper_13">
            <span class="paragraph_16">
              ビジネス変革を加速
              <br />
              SAPで創造する新たな価値
              <br />
            </span>
            <span class="paragraph_17">
              SAPを核としたビジネス変革を実現します。最適化されたプロセスと統合されたデータ管理により、企業の効率性と競争力を飛躍的に向上させます。私たちと一緒に、革新的な未来への扉を開きましょう。
              <br />
            </span>
          </div>
          <div class="image-wrapper_3 flex-col">
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/a36840b30ecc50ae749c280b526e955f.png"
            />
          </div>
        </div>
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/14c38c91aa294ddc34d469bdba267421.png"
        />
      </div>
      <div class="box_9 flex-row">
        <div class="text-wrapper_14">
          <span class="text_12">Salesforce</span>
          <span class="text_13">開発・導入支援</span>
          <span class="paragraph_19">
            <br />
            <br />
          </span>
          <span class="text_15"></span>
        </div>
      </div>
      <div class="box_10 flex-row">
        <div class="text-wrapper_15">
          <span class="paragraph_20">
            開発・導入支援
            <br />
          </span>
          <span class="text_16"></span>
        </div>
      </div>
      <div class="text-wrapper_16 flex-row">
        <span class="text_17">
          Salesforceは、ビジネスのあらゆる側面にわたってカスタマイズ可能なクラウドベースのCRM（顧客関係管理）ソリューションを提供します。私たちは、Salesforceの強力なプラットフォームを最大限に活用し、お客様のビジネスニーズに合わせた開発・導入支援を提供します。経験豊富な専門家チームによるカスタマイズから、導入後のサポートまで、お客様を全面的にサポートいたします。
        </span>
      </div>
      <div class="box_11 flex-row">
        <div class="image-wrapper_4 flex-col">
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="./assets/img/d3699b67528181b95980d361ff98bf69.png"
          />
        </div>
      </div>
      <div class="box_12 flex-row justify-end">
        <div class="image-wrapper_5 flex-col">
          <img
            class="label_4"
            referrerpolicy="no-referrer"
            src="./assets/img/91ea2aa24d13bdb5f230d8657094c9e4.png"
          />
        </div>
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/385886c4dfb307315ffaf6d870c98618.png"
        />
      </div>
      <div class="image-wrapper_6 flex-col">
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/ef99083258d14bc08cc02cb3eb81101d.png"
        />
      </div>
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/6863bf1933bc57b2d8f7bcb9cd14b710.png"
      />
      <div class="image-wrapper_7 flex-col">
        <img
          class="label_5"
          referrerpolicy="no-referrer"
          src="./assets/img/1d98f798ca63dbb0190356305f83fc20.png"
        />
      </div>
      <div class="image-wrapper_8 flex-col">
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="./assets/img/d1957a993706256c06299052c8b2b60f.png"
        />
      </div>
    </div>
    <div class="group_4 flex-col">
      <div class="text-wrapper_17">
        <span class="paragraph_21">
          Copyright&nbsp;©&nbsp;O K C
          株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_18"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />