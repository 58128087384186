<template>
  <div class="page flex-col justify-end">
    <div class="block_1 flex-col">
      <div class="text-wrapper_1 flex-col">
        <span class="text_1">OKC&nbsp;NEWS</span>
      </div>
      <div class="text-wrapper_2">
        <span class="paragraph_1">
          NEWS
          <br />
        </span>
        <span class="text_2"></span>
      </div>
      <div class="text-wrapper_3 flex-row justify-between">
        <span class="text_3">2024/04/21</span>
        <span class="text_4">13:23:11</span>
      </div>
      <span class="text_5" @click="toDetail"
        >5/16(木)〜18(土)に開催される&nbsp;e-me...</span
      >
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/c81571f809dff556049d518ed83324b7.png"
      />

      <div class="text-wrapper_4 flex-row justify-between">
        <span class="text_3">2024/04/21</span>
        <span class="text_4">13:23:11</span>
      </div>
      <span class="text_8">5/16(木)〜18(土)に開催される&nbsp;e-me...</span>
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/9b290a2c5b9b98e6d7b7e1124bbf74bf.png"
      />
      <div class="text-wrapper_5 flex-row justify-between">
        <span class="text_3">2024/04/21</span>
        <span class="text_4">13:23:11</span>
      </div>
      <span class="text_11">5/16(木)〜18(土)に開催される&nbsp;e-me...</span>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/e75f0d8d19d81cc31ccca39857e3a847.png"
      />
      <div class="text-wrapper_6 flex-row justify-between">
        <span class="text_3">2024/04/21</span>
        <span class="text_4">13:23:11</span>
      </div>
      <span class="text_14">5/16(木)〜18(土)に開催される&nbsp;e-me...</span>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/f12dd1cce2eaf6f22605d32b8620f853.png"
      />
      <div class="text-wrapper_7 flex-row justify-between">
        <span class="text_3">2024/04/21</span>
        <span class="text_4">13:23:11</span>
      </div>
      <span class="text_17">5/16(木)〜18(土)に開催される&nbsp;e-me...</span>
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/a7c16c9e2e878909fcaa996f9965b32f.png"
      />
      <div class="text-wrapper_8 flex-row justify-between">
        <span class="text_3">2024/04/21</span>
        <span class="text_4">13:23:11</span>
      </div>
      <span class="text_20">5/16(木)〜18(土)に開催される&nbsp;e-me...</span>
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="./assets/img/77d5e4a3cec037227786b17f34d28e16.png"
      />
      <div class="text-wrapper_9 flex-row justify-between">
        <span class="text_3">2024/04/21</span>
        <span class="text_4">13:23:11</span>
      </div>
      <span class="text_23">5/16(木)〜18(土)に開催される&nbsp;e-me...</span>
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        src="./assets/img/905d9b110d50d72e1cd31414c506003c.png"
      />
      <div class="text-wrapper_10 flex-row justify-between">
        <span class="text_3">2024/04/21</span>
        <span class="text_4">13:23:11</span>
      </div>
      <span class="text_26">5/16(木)〜18(土)に開催される&nbsp;e-me...</span>
      <img
        class="image_9"
        referrerpolicy="no-referrer"
        src="./assets/img/204789e2da95aef97a2cdd9785ad12b7.png"
      />
      <div class="image-wrapper_1 flex-row">
        <top-logom class="image_10" />
        <top-navm class="image_11" />
      </div>
    </div>
    <div class="block_2 flex-col">
      <div class="text-wrapper_11">
        <span class="paragraph_2">
          Copyright&nbsp;©&nbsp;O K C
          株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_27"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {
    toDetail () {
      this.$router.push('/m/index7')
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />