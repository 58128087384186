<template>
  <van-popover
    v-model="showPopover"
    trigger="click"
    theme="dark"
    :actions="actions"
    @select="onSelect"
  >
    <template #reference>
      <img
        referrerpolicy="no-referrer"
        src="./assets/img/db8cb7fa25a00cb8e60629ef476ffbd0.png"
        width="100%"
        style="cursor: pointer"
      />
    </template>
  </van-popover>
</template>
<script>
export default {
  data () {
    return {
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        { text: 'TOP', path: '/' },
        { text: 'SERVICE', path: '/m/index2' },
        { text: 'ABOUT', path: '/m/index1' },
        { text: 'WORKS', path: '/m/index3' },
        { text: 'RECRUIT', path: '/m/index4' },
        { text: 'NEWS', path: '/m/index6' },
        { text: 'CONTACT', path: '/m/index5' },
        { text: 'PRIVACY', path: '/' },
      ],
    };
  },
  methods: {
    onSelect (action) {
      this.$router.push(action.path)
    },
  },
}
</script>
<style scoped>
</style>