<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="box_1 flex-col"></div>
      <span class="text_1">OKC&nbsp;NEWS</span>
      <div class="box_2 flex-row">
        <top-logo class="image_1"></top-logo>
        <div class="section_1 flex-col justify-between">
          <top-nav></top-nav>
        </div>
      </div>
    </div>
    <div class="group_2 flex-col">
      <div class="section_2 flex-row">
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            NEWS
            <br />
          </span>
          <span class="text_2"></span>
        </div>
      </div>
      <template v-for="index in 2">
        <div class="section_3 flex-row" :key="index">
          <div class="box_6 flex-col"></div>
          <span class="text_3">2024</span>
          <span class="text_4">2024/04/21</span>
          <span class="text_5">13:23:11</span>
        </div>
        <div class="section_4 flex-row justify-between" :key="index">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/789af00f7a219563db78a83c9fe7a18b.png"
          />
          <div class="block_1 flex-col">
            <span class="text_6" @click="toDetail">
              5/16(木)〜18(土)に開催される&nbsp;e-messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
            </span>
            <div class="block_2 flex-col">
              <div class="group_3 flex-col" v-if="index == 1"></div>
            </div>
            <div class="text-wrapper_2 flex-row justify-between">
              <span class="text_7">2024/04/21</span>
              <span class="text_8">13:23:11</span>
            </div>
            <span class="text_9">
              5/16(木)〜18(土)に開催される&nbsp;e-messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
            </span>
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/35c3d1faae1cc2adfcc1386ec448dea4.png"
            />
            <div class="text-wrapper_3 flex-row justify-between">
              <span class="text_10">2024/04/21</span>
              <span class="text_11">13:23:11</span>
            </div>
            <span class="text_12">
              5/16(木)〜18(土)に開催される&nbsp;e-messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
            </span>
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/a3995771d6dcc34a9557104f863dead6.png"
            />
            <div class="text-wrapper_4 flex-row justify-between">
              <span class="text_13">2024/04/21</span>
              <span class="text_14">13:23:11</span>
            </div>
            <span class="text_15">
              5/16(木)〜18(土)に開催される&nbsp;e-messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
            </span>
          </div>
        </div>
        <div class="image-wrapper_1 flex-row" :key="index">
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/098df59f80f6b36e8f3363d2644f8876.png"
          />
        </div>
      </template>
      <div class="section_7 flex-row">
        <div class="block_3 flex-row">
          <img
            class="image_11"
            referrerpolicy="no-referrer"
            src="./assets/img/223852e5f77357d986a94965999fee51.png"
          />
          <div class="text-wrapper_8">
            <span class="paragraph_2">
              Read&nbsp;More
              <br />
            </span>
            <span class="text_29"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="group_5 flex-col">
      <div class="text-wrapper_9">
        <span class="paragraph_3">
          Copyright&nbsp;©&nbsp;O K C
          株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_30"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {
    toDetail () {
      this.$router.push('/index7')
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />