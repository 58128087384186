<template>
  <img
    referrerpolicy="no-referrer"
    src="./assets/img/6361b2bfd57bc49a4f16d7493cbbb014.png"
    @click="$router.push('/')"
  />
</template>
<script>
export default {

}
</script>
<style scoped>
</style>