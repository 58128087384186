var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page flex-col justify-end"},[_c('div',{staticClass:"image-wrapper_1 flex-row"},[_c('top-logom',{staticClass:"image_2"}),_c('top-navm',{staticClass:"image_3"})],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_1 flex-col"},[_c('div',{staticClass:"text-wrapper_1"},[_c('span',{staticClass:"paragraph_1"},[_vm._v(" その先の未来へ挑む。 "),_c('br'),_vm._v(" 人類の合理的な明るい未来ヘ。 "),_c('br')]),_c('span',{staticClass:"paragraph_2"},[_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_2 flex-col"},[_c('div',{staticClass:"text-wrapper_2"},[_c('span',{staticClass:"text_1"},[_vm._v("Our")]),_c('span',{staticClass:"paragraph_3"},[_vm._v("  Servise "),_c('br')]),_c('span',{staticClass:"text_2"})]),_c('span',{staticClass:"paragraph_4"},[_vm._v(" 新しい時代の移り変わりにリーチして、イノベーションを起こす。 "),_c('br'),_vm._v(" 私たちが行う4部門の事業内容をご紹介いたします。最新の技術で人類を未来へと導く事業です。 "),_c('br'),_vm._v(" 秒進分歩の技術革新と、新しい未来の実現をぜひご期待ください。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_3 flex-row"},[_c('img',{staticClass:"image_4",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/5634c013a6a5a4ef0da6fa34ff26141c.png")}}),_c('div',{staticClass:"group_1 flex-col"},[_c('div',{staticClass:"text-wrapper_3"},[_c('span',{staticClass:"text_3"},[_vm._v("SE")]),_c('span',{staticClass:"text_4"},[_vm._v("・")]),_c('span',{staticClass:"paragraph_5"},[_vm._v(" Consultant ")]),_c('span',{staticClass:"paragraph_6"},[_c('br'),_c('br')]),_c('span',{staticClass:"text_6"})]),_c('div',{staticClass:"text-wrapper_4"},[_c('span',{staticClass:"paragraph_7"},[_vm._v(" IT人材育成事業 "),_c('br')]),_c('span',{staticClass:"text_7"})]),_c('span',{staticClass:"paragraph_8"},[_vm._v(" 未来を創るIT人材へ "),_c('br'),_vm._v(" 技術と洞察で明日をリード "),_c('br'),_vm._v(" 技術革新の最前線で活躍するIT人材を育成しま... ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_4 flex-row"},[_c('div',{staticClass:"block_1 flex-col"},[_c('div',{staticClass:"text-wrapper_5"},[_c('span',{staticClass:"paragraph_9"},[_vm._v(" SAP "),_c('br'),_c('br')]),_c('span',{staticClass:"text_8"})]),_c('div',{staticClass:"text-wrapper_6"},[_c('span',{staticClass:"paragraph_10"},[_vm._v(" 開発・導入支援 "),_c('br')]),_c('span',{staticClass:"text_9"})]),_c('span',{staticClass:"paragraph_11"},[_vm._v(" ビジネス変革を加速 "),_c('br'),_vm._v(" SAPで創造する新たな価値 "),_c('br'),_vm._v(" SAPを核としたビジネス変革を実現します。最適化されたプロセスと統合され... ")])]),_c('div',{staticClass:"image-wrapper_2 flex-col"},[_c('img',{staticClass:"image_5",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/123ea69d9c285333a6a09be047414928.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_5 flex-row"},[_c('img',{staticClass:"image_6",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/b3296b92803f951a72da00d58bb2d1e2.png")}}),_c('div',{staticClass:"section_1 flex-col"},[_c('div',{staticClass:"text-wrapper_7"},[_c('span',{staticClass:"text_10"},[_vm._v("Salesforce")]),_c('span',{staticClass:"paragraph_12"},[_c('br')]),_c('span',{staticClass:"paragraph_13"},[_c('br'),_c('br')]),_c('span',{staticClass:"text_13"})]),_c('div',{staticClass:"text-wrapper_8"},[_c('span',{staticClass:"paragraph_14"},[_vm._v(" 開発・導入支援 "),_c('br')]),_c('span',{staticClass:"text_14"})]),_c('span',{staticClass:"text_15"},[_vm._v(" Salesforceは、ビジネスのあらゆる側面にわたってカスタマイズ可能なクラウドベースのCRM（顧客関係管理）ソリューションを提供 ")])]),_c('img',{staticClass:"image_7",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/5b6298b250ce87e56b1e2c07db047bda.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_6 flex-col"},[_c('div',{staticClass:"text-wrapper_9"},[_c('span',{staticClass:"paragraph_15"},[_vm._v(" Copyright © O K C 株式会社 All rights reserved. "),_c('br')]),_c('span',{staticClass:"text_16"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_7 flex-col"},[_c('div',{staticClass:"section_2 flex-row"},[_c('div',{staticClass:"box_8 flex-col"},[_c('div',{staticClass:"text-wrapper_10"},[_c('span',{staticClass:"paragraph_16"},[_vm._v(" Web System "),_c('br'),_c('br')]),_c('span',{staticClass:"text_17"})]),_c('div',{staticClass:"text-group_1 flex-col justify-between"},[_c('span',{staticClass:"paragraph_17"},[_vm._v(" Webシステム開発 "),_c('br'),_c('br')]),_c('span',{staticClass:"paragraph_18"},[_vm._v(" 導入支援 "),_c('br'),_c('br')])]),_c('div',{staticClass:"text-wrapper_11"},[_c('span',{staticClass:"paragraph_19"},[_vm._v(" 未来を形作るウェブソリューション "),_c('br'),_vm._v(" あなたのビジョンを現実に "),_c('br')]),_c('span',{staticClass:"paragraph_20"},[_vm._v(" 私たちは、最新の技術トレンドを駆使し、ユーザー.. "),_c('br'),_c('br')])])]),_c('img',{staticClass:"image_8",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/1651084e1f39a63fa96297f1a57664eb.png")}})])])
}]

export { render, staticRenderFns }