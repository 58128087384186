<template>
  <div class="page flex-col justify-end">
    <div class="image-wrapper_1 flex-row">
      <top-logom class="image_2" />
      <top-navm class="image_3" />
    </div>
    <div class="section_1 flex-col">
      <div class="text-wrapper_1">
        <span class="paragraph_1">
          その先の未来へ挑む。
          <br />
          人類の合理的な明るい未来ヘ。
          <br />
        </span>
        <span class="paragraph_2"><br /></span>
      </div>
    </div>
    <div class="section_2 flex-col">
      <div class="text-wrapper_2">
        <span class="text_1">Recruiting</span>
        <span class="paragraph_3">
          &nbsp;Partners
          <br />
          <br />
        </span>
        <span class="text_2"></span>
      </div>
      <span class="paragraph_4">
        OKC株式会社では、協業いただける企業様を随時募集しております。
        <br />
        ビジネスパートナーとしてお力添え頂ける企業様からのご連絡を、心よりお待ち申し上げております。
        <br />
        下記のビジネスパートナーお問い合わせフォーム。
      </span>
    </div>
    <div class="section_3 flex-col">
      <div class="text-wrapper_3">
        <span class="paragraph_5">
          Cooperative&nbsp;Consultation
          <br />
        </span>
        <span class="text_3"></span>
      </div>
      <span class="text_4">お客様基本情報</span>
      <div class="text-wrapper_4">
        <span class="paragraph_6">
          氏名
          <br />
          <br />
        </span>
        <span class="text_5"></span>
      </div>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/88d2a53804c16acbcf21a0c82704b171.png"
      />
      <div class="text-wrapper_5">
        <span class="paragraph_7">
          会社団体名
          <br />
          <br />
        </span>
        <span class="text_6"></span>
      </div>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/950b45bb10aaa82ad3c9be00fe5da530.png"
      />
      <div class="text-wrapper_6">
        <span class="paragraph_8">
          メールアドレス
          <br />
          <br />
        </span>
        <span class="text_7"></span>
      </div>
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/9b8d06c02370c8a98542caa2d36a51d7.png"
      />
      <div class="box_1 flex-row">
        <div class="text-wrapper_7">
          <span class="paragraph_9">
            お問い合わせ内容
            <br />
            <br />
          </span>
          <span class="text_8"></span>
        </div>
        <div class="text-wrapper_8">
          <span class="paragraph_10">
            SES協業について
            <br />
            <br />
            <br />
            <br />
          </span>
          <span class="text_9"></span>
        </div>
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src="./assets/img/1e02446363d7e608ff767653efcebfd0.png"
        />
      </div>
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="./assets/img/7249b8cf9f1d83d8ebf948f49c726f31.png"
      />
      <div class="text-wrapper_9">
        <span class="paragraph_11">
          お問い合わせ題名名
          <br />
          <br />
        </span>
        <span class="text_10"></span>
      </div>
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        src="./assets/img/f5c5b1aa34b21aed08c42f2db98c44d9.png"
      />
      <div class="text-wrapper_10">
        <span class="paragraph_12">
          お問い合わせ内容&nbsp;(任意)
          <br />
          <br />
        </span>
        <span class="text_11"></span>
      </div>
      <img
        class="image_9"
        referrerpolicy="no-referrer"
        src="./assets/img/81527007c5073120edc6db9493d0d407.png"
      />
      <div class="box_2 flex-row">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/bbdd1a586e4a34135d597815ac12e4e5.png"
        />
        <div class="text-wrapper_11">
          <span class="paragraph_13">
            Delivery
            <br />
          </span>
          <span class="text_12"></span>
        </div>
      </div>
    </div>
    <div class="section_4 flex-col">
      <div class="text-wrapper_12">
        <span class="paragraph_14">
          Copyright&nbsp;©&nbsp;O K C
          株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_13"></span>
      </div>
    </div>
    <div class="section_5 flex-col">
      <div class="text-wrapper_13">
        <span class="paragraph_15">
          わたしたちと未来を変える
          <br />
          有望な人材を募集。
          <br />
        </span>
        <span class="paragraph_16"><br /></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />